<template>
  <div class="articleDetail_page">
    <div class="container">
        <div class="title">{{title}}</div>
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        id: null,
        content:null,
        title:'',
    };
  },
  methods: {
    getData() {
      this.$http.ArticleDetail(`id=${this.id}`).then((res) => {
        console.log(res, "---res");
        // this.content = res.data.article.content;
        this.content = res.data.article.content.replace(/\<img/gi, '<img style="max-width:100%;height:auto" ');
        this.title = res.data.article.title
      });
    },
  },
  mounted() {
    console.log(this.$route.params.id, "---this.$route.params.id;");
      this.id = this.$route.params.id;
      this.getData();
  },
  created () {
    setTimeout(() => {
      document.title = this.title + '---中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
    }, 1000)
 },
};
</script>
<style lang="scss" scoped>
.articleDetail_page {
}
.container {
  width: 100%;
  margin-top: 50px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  padding: 20px;
  .title{
      font-size: 20px;
      text-align: center;
      font-weight: 800;
  }
}
.content {
  img {
    width: 100% !important;
  }
}
</style>